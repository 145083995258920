import React from 'react'

import LoadingPane from '../Shared/LoadingPane'

const ForgotPassword = (props) => {
    const { goToBaseRoute, handleChange, mobile, save, state } = props
    const { error, loading } = state

    return (
        <div className={`forgot-password-view full-screen-view ${mobile ? 'mobile' : ''}`}>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='una-logo' onClick={goToBaseRoute}>
                <img src={require('../../theme/assets/hire-logo.png')} alt='una-logo' />
            </div>

            <div className='content-frame'>
                <div className='centered-frame'>
                    <div className='header'>Reset Password</div>

                    <div className='message'>If the email address is in our system, you should receive an email with the link to reset your password.</div>

                    <div className='form-frame'>
                        <input
                            className='form-input'
                            onChange={e => handleChange('email', e.target.value)}
                            placeholder='Enter email address'
                        />

                        <div className={error ? 'error-label red' : 'hide'}>{error}</div>

                        <button className='reset-password-button' onClick={save}>Send Email</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword