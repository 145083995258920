import React from 'react'

import _ from 'lodash'

import STRINGS from '../../localization'

const Roles = (props) => {
    const { goToCredentialsRoute, handleChange, state } = props
    const { mobile, roles, role } = state

    return (
        <div className={`${mobile ? 'roles-view mobile' : 'roles-view'} full-screen-view bg-grey-2`}>
            <div className='una-logo'>
                <img src={require('../../theme/assets/hire-logo.png')} alt='una-logo' />
            </div>

            <div className='roles-frame layout-center'>
                <div className='header-frame'>
                    <button className='action-button hide' onClick={goToCredentialsRoute}>{STRINGS.cancel}</button>

                    <div className='header'>{STRINGS.selectOrg}</div>

                    <button className={state.role.id ? 'action-button bg-una' : 'action-button disabled'} onClick={goToCredentialsRoute}>{STRINGS.done}</button>
                </div>

                <ul className='roles-list-view scroll'>
                    {_.orderBy(roles, ['role', 'org.title'], ['asc', 'asc']).map(r => {
                        const { id, org, role: name } = r
                        const { attachment, title } = org

                        let checked = (role.id === id)

                        return (
                            <li className='role-cell pointer' key={id} onClick={() => handleChange('role', r)}>
                                <div className='left-section'>
                                    <input
                                        className='role-radio'
                                        checked={checked}
                                        readOnly={true}
                                        type='radio'
                                    />

                                    <div className='role-details-frame'>
                                        <div className='role-label'>{title}</div>
                                        <div className='role-label hint'>{_.startCase(name)}</div>
                                    </div>
                                </div>

                                <div className='right-section'>
                                    {attachment ?
                                        <div className='company-logo'>
                                            <img className={checked ? '' : 'disabled'} src={attachment.url} alt='company-logo' />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Roles