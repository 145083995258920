import React from 'react'

import LoadingPane from '../Shared/LoadingPane'

const Login = (props) => {
    const { forgotPassword, login, handleChange, state } = props
    const { email, error, loading, mobile, password } = state

    return (
        <div className={mobile ? 'login-view mobile' : 'login-view'}>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.03)' }} /> : null}

            <div className='content-view'>
                <div className='header-frame'>
                    <div className='login-logo'>
                        <img
                            className='logo'
                            src={require('../../theme/assets/hire-logo.png')}
                            alt='una-logo'
                            style={{ height: 36 }}
                        />
                    </div>

                    <div className='header-label'>Login to UNA Test</div>
                </div>

                <div className='form-frame'>
                    <input
                        autoFocus
                        className='login-input'
                        defaultValue={email}
                        onChange={e => handleChange('email', e.target.value)}
                        placeholder='Email'
                        type='text'
                    />

                    <input
                        className='login-input'
                        defaultValue={password}
                        onChange={e => handleChange('password', e.target.value)}
                        placeholder='Password'
                        type='password'
                    />
                </div>

                <div className='button-frame'>
                    <div className={error ? 'error-label red' : 'hide'}>{error}</div>

                    <button className='login-button' onClick={login}>Login</button>

                    <button className='forgot-password-button red' onClick={forgotPassword}>Forgot Password?</button>
                </div>
            </div>

            <div className='version-label hint'>{`V${process.env.REACT_APP_VERSION}`}</div>
        </div>
    )
}

export default Login